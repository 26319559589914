article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary { display: block; }
body, ol, ul, li, div, p, span, form, h1, h2, h3, h4, h5, h6, table, thead, th, tbody, tr, td, img, input, textarea, dd, dt, dl { word-wrap: break-word; outline: none; padding: 0; margin: 0; -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
textarea { overflow: auto; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }
h1, h2, h3, h4, h5, h6, p, ul, ol, dd, dt, dl { -webkit-margin-before: 0px; -webkit-margin-after: 0px; -webkit-margin-start: 0px; -webkit-margin-end: 0px; }
a img, img { border: none; outline: none; display: block; width: auto; max-width: 100%; }
a { cursor: pointer; text-decoration: none; outline: 0; }
input { -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; outline: none; }
html { font-size: 62.5%; } 
body { -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; -ms-font-smoothing: antialiased; background-color: $white; font-size: 62.5%; overflow-x: hidden; }
.no-scroll{ overflow: hidden !important; height: 100%;}
ul, menu, dir { margin: 0px; padding: 0px; display: block; list-style-type: disc; -webkit-margin-before: 0; -webkit-margin-after: 0; -webkit-margin-start: 0; -webkit-margin-end: 0; -webkit-padding-start: 0; }
* { -webkit-box-sizing: border-box; box-sizing: border-box;
    &:after, &:before {  -webkit-box-sizing: border-box; box-sizing: border-box; }
}
.clear { clear: both; height: 0px; overflow: hidden; width: auto; display: block; float: none !important; }
li:last-child, p:last-child { margin-bottom: 0 !important; }
img { -o-object-fit: cover !important; object-fit: cover !important;}
select{ outline: none; cursor: pointer; }