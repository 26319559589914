/*
primary color background = #1d1d1d

#fd2155 - pink 
#08fdd8 - blue
#262626 - grey
*/
/*
#1A1A2E - dark grey
#16213E - dark blue
#0F3460 - dark blue
#E94560 - pink
*/
$white: #fff;
$secondaryWhite: #E5E5E5;
$primaryDark : #1d1d1d;
$primaryLight : #262626;
$pink : #fd2155;
$blue : #08fdd8;
$green :#5dea62;
$yellow : #e9e41d;
$darkgrey : #1A1A2E;


// Colors
$bg-color: #000000;
$dot-color: #5f5f5f;

// Dimensions
$dot-size: 1.2px;
$dot-space: 22px;

// font 
$bold-font: 'Bungee', cursive;