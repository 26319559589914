.home_section {
    height: 100vh;
    width: 100%;
    overflow: hidden;
    position: relative;

    .top_Section {
        z-index: 9;
        height: 100%;
        width: 100%;
        @include flex-center();
        flex-direction: column;
        background:
            linear-gradient(90deg, $bg-color ($dot-space - $dot-size), transparent 1%) center,
            linear-gradient($bg-color ($dot-space - $dot-size), transparent 1%) center,
            $dot-color;
        background-size: $dot-space $dot-space;

        .title {
            background-color: $yellow;
            height: 60px;
            @include flex-center();
            padding: 0 3px;
            border-radius: 2px;

            h1 {
                margin: 0;
                color: $bg-color;
                font-size: 65px;
                font-family: $bold-font;
                line-height: 0;
                user-select: none;
                // font-family: 'Exo 2', sans-serif;
            }
        }

        .sub_title {

            p,
            span {
                color: $secondaryWhite;
                font-size: 20px;
                font-weight: 300;
                margin: 0;
                line-height: 1.5;
            }
        }
    }
}

// best in chrome
$total: 300; // total particles
$orb-size: 300px;
$particle-size: 2px;
$time: 7s;
$base-hue: 0; // change for diff colors (180 is nice)

.wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
    transform-style: preserve-3d;
    perspective: 1000px;
    transform: translate(-50%, -50%);
    animation: rotate $time infinite linear; // rotate orb
}

@keyframes rotate {
    100% {
        transform: rotateY(360deg) rotateX(360deg);
    }
}

.c {
    position: absolute;
    width: $particle-size;
    height: $particle-size;
    border-radius: 50%;
    opacity: 0;
}

@for $i from 1 through $total {
    $z: (random(360) * 1deg); // random angle to rotateZ
    $y: (random(360) * 1deg); // random to rotateX
    $hue: ((40/$total * $i) + $base-hue); // set hue

    .c:nth-child(#{$i}) {
        // grab the nth particle
        animation: orbit#{$i} $time infinite;
        animation-delay: ($i * .01s);
        background-color: hsla($hue, 100%, 100%, 1);
    }

    @keyframes orbit#{$i} {
        20% {
            opacity: 1; // fade in
        }

        30% {
            transform: rotateZ(-$z) rotateY($y) translateX($orb-size) rotateZ($z); // form orb
        }

        80% {
            transform: rotateZ(-$z) rotateY($y) translateX($orb-size) rotateZ($z); // hold orb state 30-80
            opacity: 1; // hold opacity 20-80
        }

        100% {
            transform: rotateZ(-$z) rotateY($y) translateX(($orb-size * 3)) rotateZ($z); // translateX * 3
        }
    }
}