@charset "UTF-8";
@import './scss/variables';
@import './scss/mixins';
@import './scss/profile';
@import './scss/base';
@import './scss/sidebar';


body {
  background: $primaryDark;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // flex-direction: column;
}



#root{
  height: 100%;
  width: 100%;
}
.snakeBg{
  // height: 100vh;
  // width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  background: rgb(15, 2, 2);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.snake_game {

  h1 {
    color: $white;
    // font-size: 50px;
  }

  canvas {
    // border: 1px solid black;
  }

}

.ComingSoon {
  color: $white;
  font-size: 50px;
  color: $green;
  font-family: Monaco, monospace;
  font-size: 24px;
  width: 100%;
  text-align: center;
  position: absolute;
  top: 45%;
  left: 0;
  animation: 120ms infinite normal glitch;

  span {
    animation: 1.5s infinite normal cursor;
  }

  &.link {
    top: auto;
    position: unset;
    margin-top: 20px;
   
    a{
      color: $green;
      text-decoration: none;
      font-style: italic;
    }
  }
}


::-moz-selection {
  background: $yellow;
  color: $primaryDark;
}

::selection {
  background:  $yellow;
  color: $primaryDark;
}

@keyframes glitch {
  0% {
    opacity: 0;
    left: 0;
  }

  40%,
  80% {
    opacity: 1;
    left: -2px;
  }
}

@keyframes cursor {
  0% {
    opacity: 0;
    left: 0;
  }

  40% {
    opacity: 0;
    left: -2px;
  }

  80% {
    opacity: 1;
    left: -2px;
  }
}